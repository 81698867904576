<template>
  <el-container id="app">
    <transition name="slide-left-out">
      <el-aside v-show="menuVisible" width="360px">
        <Menu />
      </el-aside>
    </transition>
    <el-main>
      <Canvas />
      <ActionBar />
    </el-main>
  </el-container>
</template>

<script>
import { EventBus } from "./plugins/EventBus";

import Canvas from "./components/Canvas.vue";
import Menu from "./components/LeftMenu.vue";
import ActionBar from "./components/MainActionBar.vue";

export default {
  name: "App",
  components: {
    Menu,
    Canvas,
    ActionBar,
    // AddToCartDialog,
  },
  data() {
    return {
      menuVisible: true,
    };
  },
  mounted() {
    EventBus.$on("close-menu", () => {
      this.menuVisible = false;
    });
    EventBus.$on("open-menu", () => {
      this.menuVisible = true;
    });
  },
  methods: {},
  watch: {
    menuVisible() {
      setTimeout(() => {
        EventBus.emitPageResize();
      }, 350);
    },
  },
};
</script>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 100vw !important;
  max-height: 100vh !important;

  .el-main {
    padding: 0;
  }

  .el-aside {
    box-shadow: 0px 0px 30px #04040533;
    overflow: hidden;
    z-index: 9;
    width: @--menu-width;
  }

  .slide-left-out-enter-active {
    transition: all 0.3s ease;
  }
  .slide-left-out-leave-active {
    transition: all 0.3s;
  }
  .slide-left-out-enter,
  .slide-left-out-leave-to {
    transform: translateX(-360px);
    opacity: 0;
  }
}
</style>
