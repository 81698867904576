import Vue from "vue";
import Vuex from "vuex";
// import VueCookie from "vue-cookie";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import cart from "./modules/cart";
import user from "./modules/user";
import aste from "./modules/aste";
import frontali from "./modules/frontali";

Vue.use(Vuex);
// Vue.use(VueCookie);

export default new Vuex.Store({
  state: {
    frontale: "", //modello frontale
    calibro: "",
    naso: "",
    asianFitting: false, //colore frontale
    colore: "",
    finitura: "S", //finitura frontale
    coloreAste: "",
    coloreMetalloAste: "PRO0000272", //codice items dell'asta gun o gold
    finituraAste: "S",
    templesMaterial: "PL",
    misuraAste: "145",
    nome: "", //nome personalizzato
    coloreNome: "", //colore nome personalizzato
    note: "",
    confirm: false,
    quantity: 1,
    id_for_url: "",
    qrcode: "",
    qrcodeUrl: "",
    //loadingGeometry: false, //si attiva durante il caricamento di una geometria
    // cartRows: [],
    configurations: [],
    animateToFront: false,
    animateToTemple: false,
    // user: {},
    // checkoutSuccess: false,
    // checkingOut: false, //true quando in attesa di risposta per checkout
    // frontali: [], // raccoglie i dati dei frontali comprensivi di prezzi
    // aste: [],
  },
  mutations,
  getters,
  actions,
  modules: {
    cart,
    user,
    aste,
    frontali,
  },
});
