<template>
  <el-dialog
    id="error-add-to-cart-dialog"
    :visible="isVisible"
    :modal="false"
    @close="$emit('close')"
    width="380px"
  >
    <el-container class="dialog-body">
      <span
        >SI È VERIFICATO UN'ERRORE E NON È STATO POSSIBILE AGGIUNGERE IL
        PRODOTTO AL CARRELLO, <br />
        RIPROVA...</span
      >
    </el-container>
    <span slot="footer" class="add-to-cart-actions">
      <el-button type="primary" @click="$emit('close')">CHIUDI</el-button>
    </span>
  </el-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { EventBus } from "../../plugins/EventBus";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" >
#add-to-cart-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .dialog-body {
    display: flex;
    justify-content: center;
    color: @--color-primary;
  }

  .add-to-cart-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>