// import _ from 'lodash'
// import jslinq from 'jslinq'
// import ajax from "../plugins/ajax_request";

export default {
  componentsErrors(state, getters) {
    let allErrors = [],
      aste = { temples: getters["aste/checkErrors"] },
      frontal = { frontal: getters["frontali/checkErrors"] };

    allErrors.push(aste);
    allErrors.push(frontal);

    return allErrors;
  },
  frontalProgress: (state) => {
    let totalOptions = 3;
    let selectedOptions = 0;

    if (state.frontale !== "") {
      selectedOptions += 1;
    }

    if (state.calibro !== "" && state.naso !== "") {
      selectedOptions += 1;
    }

    if (state.asianFitting !== "") {
      selectedOptions += 1;
    }

    return (selectedOptions / totalOptions) * 100;
  },
  frontalProgressComplete: (state, getters) => {
    return getters.frontalProgress == 100;
  },
  colorProgress: (state) => {
    let totalOptions = 2;
    let selectedOptions = 0;

    if (state.finitura !== "") {
      selectedOptions += 1;
    }

    if (state.colore !== "") {
      selectedOptions += 1;
    }

    return (selectedOptions / totalOptions) * 100;
  },
  colorProgressComplete: (state, getters) => {
    return getters.colorProgress == 100;
  },
  frontModel: (state) => {
    return state.frontale ? state.frontale.U_THE_MODELLO : null;
  },
  frontSize: (state) => {
    if (state.calibro && state.naso) {
      return state.calibro + "/" + state.naso;
    }
  },
  frontColor: (state) => {
    return state.colore ? state.colore.U_THE_COLORE : null;
  },
  templesColor: (state) => {
    return state.coloreAste;
  },
  templesMaterial: (state) => {
    return state.templesMaterial;
  },
  haveConfigurations: (state) => {
    return state.configurations.length > 0;
  },
  // userIsGuest: (state) => {
  //   return _.isEmpty(state.user);
  // },
  // cartTotal: (state) => {
  //   return state.cartRows.length;
  // },
  // getCartRows: (state) => {
  //   return state.cartRows;
  // },
  configurationComplete: (state) => {
    return (
      state.frontale != "" &&
      state.calibro != "" &&
      state.naso != "" &&
      state.colore != "" &&
      state.finitura != "" &&
      state.coloreAste != ""
    );
  },
  getActiveConfiguration: (state) => {
    let c = {
      frontale: state.frontale, //modello frontale
      calibro: state.calibro,
      naso: state.naso,
      asianFitting: state.asianFitting,
      colore: state.colore,
      finitura: state.finitura,
      coloreAste: state.coloreAste,
      coloreMetalloAste: state.coloreMetalloAste,
      finituraAste: state.finituraAste,
      misuraAste: state.misuraAste,
      nome: state.nome,
      coloreNome: state.coloreNome,
      note: state.note,
    };


    return c;
  },
  // getActiveConfigurationToString: ({ getters }) => {
  //   var activeConf = getters.getActiveConfiguration();
  //   var activeConfJSON = JSON.stringify(activeConf);

  //   return activeConfJSON;
  // },
  isConfigurationSaved: (state) => {
    if (state.configurations.length > 0) {
      let c = {
        frontale: state.frontale, //modello frontale
        calibro: state.calibro,
        naso: state.naso,
        asianFitting: state.asianFitting,
        colore: state.colore,
        finitura: state.finitura,
        coloreAste: state.coloreAste,
        finituraAste: state.finituraAste,
        misuraAste: state.misuraAste,
        nome: state.nome,
        coloreNome: state.coloreNome,
        note: state.note,
      };
      let conf = state.configurations.filter((cfg) => {
        return (
          cfg.frontale == c.frontale && //modello frontale
          cfg.calibro == c.calibro &&
          cfg.naso == c.naso &&
          cfg.asianFitting == c.asianFitting &&
          cfg.colore == c.colore &&
          cfg.finitura == c.finitura &&
          cfg.coloreAste == c.coloreAste &&
          cfg.finituraAste == state.finituraAste &&
          cfg.misuraAste == c.misuraAste &&
          cfg.nome == c.nome &&
          cfg.coloreNome == c.coloreNome &&
          cfg.note == c.note
        );
      });
      return conf.length > 0;
    }

    return false;
  },
  urlParams: () => {
    /**
     * URL esempio url_base/<modello>/[F|f]-<colore_frontale>/<[A|a][M|m]-<colore_asta>-[GOLD|GUN]
     * - modello: codice modello così com'è
     * - colore frontale: F identifica che si tratta del colore frontale il codice va messo per esteso es: PL0010
     * - colore asta: A identifica che si tratta di colore asta, M è opzionale e indica se è di metallo, il colore va messo per esteso, GOLD o GUN specificano il colore del metallo
     */
    let url = window.location.href;
    let data = url.split("/");
    let components = {};
    //id = id[id.length-1];

    data.forEach((element) => {
      if (element.match(/\w\w-\d\d\dC/)) {
        //se faccio il match con un presunto codice modello carico quel modello
        // l'espressione regolare è uguale nele configurazioni yii dell'urlManager
        /*let f = this.items.filter(fr => {
          return fr.U_THE_MODELLO == element
        });*/
        //this.setFrontal(f[0]);
        components.modello = element;
      } else if (element.match(/[F|f]-PL\d{4}/)) {
        //si dovrebbe trattare del colore frontale
        components.coloreFrontale = element
          .match(/[F|f]-PL\d{4}/)[0]
          .toUpperCase()
          .replace("F-", "");
      } else if (element.match(/[A|a][M|m]?-PL\d{4}(\w{3,4})?/)) {
        //si dovrebbe trattare del colore aste
        let c = element.match(/[A|a][M|m]?-PL\d{4}(\w{3,4})?/);
        c = c[0].split("-");
        //c[0] = A or AM
        //c[1] = cod colore
        //c[2] = se ho la M contiene GUN o GOLD
        components.coloreAste = c[1];
        if (c[0].match(/[m|M]/)) {
          components.materialeAste = c[2];
        }
      }
    });
    return components;
  },
};
