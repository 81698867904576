<template>
  <el-dialog
    id="checkout-dialog"
    append-to-body
    :visible="visible"
    @close="$emit('close')"
    width="600px"
  >
    <span>ORDINE ESEGUITO CORRETTAMENTE</span>
    <el-row slot="footer">
      <el-button type="primary" @click="$emit('close-cart')"
        >RITORNA AL CONFIGURATORE</el-button
      >
      <el-button type="primary" @click="openHistory"
        >VAI ALLO STORICO ORDINI</el-button
      >
    </el-row>
  </el-dialog>
</template>

<script>
import { EventBus } from "../../../plugins/EventBus";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openHistory() {
      EventBus.$emit("open-history");
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>