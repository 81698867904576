// import Vue from "vue";
import ajax from "../../plugins/ajax_request";

const state = () => ({
  cartRows: [],
  configurations: [],
  checkoutSuccess: false,
  checkingOut: false,
});

const getters = {
  canAddToCart: (state, getters, rootState, rootGetters) => {
    let guest = rootGetters["user/isGuest"];
    let configurationComplete = rootGetters.configurationComplete;
    // if (configurationComplete && !guest) {
    //   return true;
    // }
    // return false;

    return configurationComplete && !guest;
  },
  // eslint-disable-next-line no-unused-vars
  rowNumber: (state, getters, rootState, rootGetters) => {
    return state.cartRows.length;
  },
};

const mutations = {
  setCartRows(state, rows) {
    state.cartRows = rows;
  },
  emptyCart: (state) => {
    state.cartRows = [];
  },
  setCheckoutStatus: (state, status) => {
    state.checkoutSuccess = status;
  },
  setCheckingOut: (state, s) => {
    state.checkingOut = s;
  },
};

const actions = {
  async addToCart({ commit, rootState }) {
    let rows = [];
    let row = {
      ItemCode: rootState.frontale.ItemCode,
      colore_frontale: rootState.colore.U_THE_COLORE,
      quantita: 1,
      note: rootState.note,
      TempleCode: rootState.coloreAste.ItemCode,
      FinishingTemple: rootState.finituraAste,
      calibro: rootState.calibro,
      naso: rootState.naso,
      finishing: rootState.finitura,
      asian_fitting: rootState.asianFitting ? 1 : 0,
      colore_nome:
        rootState.nome && rootState.nome.length > 0 ? rootState.coloreNome : "",
      font_nome: "",
      nome: rootState.nome,
      coloreMetalloAste: (rootState.METALTEMPLE == 1) ? rootState.coloreMetalloAste : '',
      // quantita: rootState.quantity,
    };
    rows.push(row);
    rootState.note = "";
    let d = await ajax.addToCart(rows)
    if (d.data.status.success) {
      rootState.nome = "";
      rootState.coloreNome = "";
      commit("setCartRows", d.data.cart); //status.cartRows = d.cart;
    }
    return d;
  },
  addSampleToCart({ commit, state }, rows) {
    // let auth_key = Vue.$cookies.get("_identity");
    return ajax.addToCart(rows).then((response) => {
      let d = response.data;
      if (d.status.success) {
        state.nome = "";
        state.coloreNome = "";
        commit("setCartRows", d.cart); //status.cartRows = d.cart;
      }
    });
  },
  deleteCartRow({ commit }, id) {
    // let auth_key = Vue.$cookies.get("_identity");
    ajax.deleteCartRow(id).then((response) => {
      let d = response.data;
      if (d.status == true) {
        commit("setCartRows", d.cart); //status.cartRows = d.cart;
      }
    });
  },
  // eslint-disable-next-line no-unused-vars
  async asyncDeleteCartRow({ commit }, id) {
    return await ajax.deleteCartRow(id);
  },
  emptyCart(commit) {
    ajax.emptyUserCart().then((response) => {
      let d = response.data;
      if (d == true) {
        commit("emptyCart");
      }
    });
  },
  /**
   * Operazione di checkout su carrello attivo,
   * esegue il checkout e aggiorna delle variabili di stato 
   * per definire quando il checkout è in esecuzione e l'esito dell'operazione.
   * Al termine dell'operazione pulisce il carrello locale
   */
  async checkout({ commit, rootState }, note) {
    const response = await ajax.checkout(note),
      d = response.data;
    if (response.status >= 200 &&
      response.status < 300 && parseInt(d.order_id) > 0) {
      commit("setCheckoutStatus", true);
      commit("setCheckingOut", false);
      commit("emptyCart");
      rootState.note = '';
    } else {
      commit("setCheckoutStatus", false);
    }

    return response;
  },
  async asyncCheckout(note) {
    return await ajax.checkout(note);
  },
  async checkoutRow(commit, row, note) {
    return await ajax.checkoutRow(row, note);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
