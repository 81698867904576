<template>
  <div id="main-action-bar">
    <ul class="action-list">
      <li v-if="isTablet">
        <div
          class="action-wrapper"
          @click="mainMenuOpen ? emitCloseMenu() : emitOpenMenu()"
        >
          <Close v-if="mainMenuOpen" class="action-icon"></Close>
          <Menu v-else class="action-icon"></Menu>
        </div>
      </li>
      <li>
        <div
          class="action-wrapper"
          @click="
            resetConfiguration({
              forma: null,
              colore: null,
              coloreAste: null,
            });
            emitResetConfiguration();
          "
        >
          <Restore class="action-icon"></Restore>
          <span class="action-text">Ripristina</span>
        </div>
      </li>
      <li>
        <div class="action-wrapper" @click="loginDialogVisible = true">
          <Account class="action-icon"></Account>
          <span class="action-text"> Profilo </span>
        </div>
        <LoginDialog
          :visible="loginDialogVisible"
          @close="loginDialogVisible = false"
        />
      </li>
      <li v-if="!isGuest">
        <div class="action-wrapper" @click="cartDialogVisible = true">
          <el-badge :value="cartRows.length" type="primary">
            <Cart class="action-icon"></Cart>
          </el-badge>
          <span class="action-text">Carrello</span>
        </div>
        <CartDialog
          :visible="cartDialogVisible"
          @close="cartDialogVisible = false"
        />
      </li>
      <li v-if="!isGuest">
        <div class="action-wrapper" @click="historyDialogVisible = true">
          <Storico class="action-icon"></Storico>
          <span class="action-text">Storico ordini</span>
        </div>
        <HistoryDialog
          :visible="historyDialogVisible"
          @close="historyDialogVisible = false"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { EventBus } from "../plugins/EventBus";

import Account from "vue-material-design-icons/Account.vue";
import Restore from "vue-material-design-icons/History.vue";
import Cart from "vue-material-design-icons/Cart.vue";
import Storico from "vue-material-design-icons/SortVariant.vue";
import Close from "vue-material-design-icons/Close.vue";
import Menu from "vue-material-design-icons/Menu.vue";

import LoginDialog from "./main-action-bar/LoginDialog.vue";
import CartDialog from "./main-action-bar/CartDialog.vue";
import HistoryDialog from "./main-action-bar/HistoryDialog.vue";

export default {
  components: {
    Account,
    Restore,
    Cart,
    Storico,
    Close,
    Menu,
    LoginDialog,
    CartDialog,
    HistoryDialog,
  },
  data() {
    return {
      loginDialogVisible: false,
      cartDialogVisible: false,
      historyDialogVisible: false,
      isMobile: false,
      isTablet: false,
      isMobileOnly: false,
      mainMenuOpen: true,
    };
  },
  async mounted() {
    let self = this;

    this.isMobile = EventBus.deviceIsMobile;
    this.isTablet = EventBus.deviceIsTablet;
    this.isMobileOnly = EventBus.deviceIsMobileOnly;

    await this.getUserData();
    EventBus.$on("open-cart", function () {
      self.cartDialogVisible = true;
    });
    EventBus.$on("open-history", function () {
      self.historyDialogVisible = true;
    });

    EventBus.$on("page-resize", (pr) => {
      this.isMobile = pr.isMobile;
      this.isTablet = pr.isTablet;
      this.isMobileOnly = pr.isMobileOnly;
    });
  },
  methods: {
    ...mapActions("user", ["getUserData"]),
    ...mapMutations(["resetConfiguration"]),
    emitCloseMenu() {
      EventBus.$emit("close-menu");
      this.mainMenuOpen = false;
    },
    emitOpenMenu() {
      EventBus.$emit("open-menu");
      this.mainMenuOpen = true;
    },
    emitResetConfiguration() {
      EventBus.$emit("reset-configuration");
    },
  },
  computed: {
    ...mapGetters("user", ["isGuest"]),
    ...mapState("cart", ["cartRows"]),
  },
};
</script>

<style lang="less" >
#main-action-bar {
  position: absolute;
  top: 0;
  .action-list {
    padding-inline-start: 8px;
    display: flex;
    & > li {
      display: inline-block;
      margin: auto 10px;

      .action-wrapper {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .action-icon {
          svg {
            width: 40px;
            height: 40px;
            fill: @--color-primary;
          }
        }
      }
    }
  }
}
</style>