<template>
  <el-dialog id="cart-dialog" :visible="visible" :fullscreen="true" @close="$emit('close')" @open="loadPage(page)"
    v-loading="waitingCheckout">
    <span slot="title" class="dialog-title">
      <Cart />CARRELLO
    </span>
    <el-row class="filter-row">
      <el-col :span="24">
        <el-input size="small" placeholder="Cerca" v-model="filter" clearable></el-input>
        <Search class="input-icon" />
      </el-col>
    </el-row>
    <el-row class="table-row">
      <el-col :span="24">
        <el-table :data="rowsPage" border v-loading="waitingTableAction" style="width: 100%">
          <el-table-column width="150" prop="nome_modello" label="MODELLO"></el-table-column>
          <el-table-column width="150" label="MISURA">
            <template slot-scope="scope">{{ scope.row.calibro }}/{{ scope.row.naso }}</template>
          </el-table-column>
          <el-table-column width="150" label="COLORE FRONTALE">
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row && warnings[scope.row.colore_frontale]"
                :content="warnings[scope.row.colore_frontale]" effect="light" placement="top">
                <span style="position: absolute; right: 0; top: 0;"><svg width="27" height="27" fill="red"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="m22.454 20.992-9.87-18.334a.375.375 0 0 0-.661 0L2.051 20.993a.375.375 0 0 0 .33.552h19.745a.374.374 0 0 0 .328-.553Zm-9.264-1.7h-1.874v-1.876h1.874v1.875Zm-.187-2.997h-1.5l-.281-7.5h2.062l-.281 7.5Z">
                    </path>
                  </svg></span>
              </el-tooltip>
              <span>{{ scope.row.colore_frontale.replace('PL', '') }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="finishing" label="FINITURA">
            <template slot-scope="scope">{{ scope.row.finishing | finishingLabel }}</template>
          </el-table-column>
          <el-table-column width="150" prop="nome_asta" label="ASTE"></el-table-column>
          <el-table-column width="150" prop="FinishingTemple" label="FINITURA">
            <template slot-scope="scope">{{ scope.row.FinishingTemple | finishingLabel }}</template>
          </el-table-column>
          <el-table-column width="150" prop="nome" label="NOME"></el-table-column>
          <el-table-column width="150" prop="colore_nome" label="COLORE NOME"></el-table-column>
          <el-table-column width="150" prop="note" label="NOTE"></el-table-column>
          <el-table-column width="150" prop="quantita" label="QUANTITÀ"></el-table-column>
          <el-table-column width="150" label fixed="right">
            <template slot-scope="scope">
              <el-button class="material-icon-button" type="danger" plain @click="deleteRow(scope.row.id)" size="mini">
                ELIMINA
                <Delete />
              </el-button>
            </template>
          </el-table-column>
          <el-table-column width="150" label fixed="right">
            <template slot-scope="scope">
              <el-button class="material-icon-button" type="success" size="mini" plain @click="
                waitingPartialCheckoutConfirm = scope.row.id;
              checkoutConfirmDialogVisible = true;
              ">
                CHECKOUT
                <Check />
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="pagination-row" layout="total, prev, next" :total="cartRows.length" @prev-click="loadPage"
          @next-click="loadPage" :page-size="pageSize"></el-pagination>
      </el-col>
    </el-row>
    <el-row class="note-row">
      <span>NOTE</span>
      <el-input type="textarea" placeholder="Digita qui" v-model="note"></el-input>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="$emit('close')">RITORNA AL CONFIGURATORE</el-button>
      <el-button type="primary" @click="
        waitingFullCheckoutConfirm = true;
      checkoutConfirmDialogVisible = true;
      " :disabled="cartRows.length == 0">CONFERMA ORDINE</el-button>
    </el-row>

    <CheckoutDialog :visible="checkoutDialogVisible" @close="checkoutDialogVisible = false"
      @open-history="$emit('close')" @close-cart="
        checkoutDialogVisible = false;
      $emit('close');
      " />

    <CheckoutErrorDialog :visible="checkoutErrorDialogVisible" @close="checkoutErrorDialogVisible = false"
      @cart-checkout="cartCheckout()" />

    <DeleteErrorDialog :visible="deleteErrorDialogVisible" @close="deleteErrorDialogVisible = false" />

    <CheckoutRowErrorDialog :visible="checkoutRowErrorDialogVisible" @close="checkoutRowErrorDialogVisible = false" />

    <ConfirmCheckoutDialog :visible="checkoutConfirmDialogVisible" @close="checkoutConfirmDialogVisible = false"
      @checkout-confirmed="confirmCheckout" />
  </el-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";
import jslinq from "jslinq";

import ajax_request from "../../plugins/ajax_request";
import { EventBus } from "../../plugins/EventBus";

import Components from "./cart-dialog";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: Components,
  data() {
    return {
      filter: "",
      page: 1,
      pageSize: 4,
      rowsPage: [],
      // note: "",
      checkoutDialogVisible: false,
      checkoutErrorDialogVisible: false,
      deleteErrorDialogVisible: false,
      checkoutRowErrorDialogVisible: false,
      checkoutConfirmDialogVisible: false,
      waitingCheckout: false,
      waitingTableAction: false,
      waitingFullCheckoutConfirm: false,
      waitingPartialCheckoutConfirm: 0, //id della riga da confermare
    };
  },
  mounted() {
    EventBus.$on("open-history", () => {
      this.$emit("close");
    });
  },
  methods: {
    ...mapActions("cart", ["checkout", "asyncDeleteCartRow", "getUserCart"]),
    ...mapActions("user", ["getUserCart"]),
    ...mapMutations("cart", ["setCartRows"]),
    loadPage(e) {
      if (e != 1) {
        let start = (e - 1) * this.pageSize,
          end = start + this.pageSize;
        this.rowsPage = _.slice(this.filteredRows, start, end);
        this.page = e;
      } else {
        this.rowsPage = _.slice(this.filteredRows, 0, this.pageSize);
        this.page = e;
      }
    },
    /***
     * DEPRECATA - inserita nel CheckoutDialog
     */
    // TODO: ELiminare funzione!
    // openHistory() {
    //   this.checkoutDialogVisible = false;
    //   this.$emit("close");
    // },
    confirmCheckout() {
      if (this.waitingFullCheckoutConfirm) {
        this.cartCheckout();
      } else if (this.waitingPartialCheckoutConfirm > 0) {
        this.checkoutRow(this.waitingPartialCheckoutConfirm);
      }

      this.waitingPartialCheckoutConfirm = 0;
      this.waitingFullCheckoutConfirm = false;
      this.checkoutConfirmDialogVisible = false;
    },
    async cartCheckout() {
      this.waitingCheckout = true;
      let response = await this.checkout(this.note);
      if (parseInt(response.data.order_id) > 0) {
        this.checkoutDialogVisible = true;
      } else {
        this.checkoutErrorDialogVisible = true;
      }
      this.waitingCheckout = false;
    },
    async deleteRow(id) {
      this.waitingTableAction = true;
      let response = await this.asyncDeleteCartRow(id);
      if (response.data.status) {
        this.setCartRows(response.data.cart);
      } else {
        this.deleteErrorDialogVisible = true;
      }
      this.waitingTableAction = false;
    },
    async checkoutRow(id) {
      // TODO: verificare se necessaria conferma checkout riga
      let result = await ajax_request.checkoutRow(parseInt(id), this.note);

      if (
        result.status >= 300 ||
        result.status < 200 ||
        _.isEmpty(result.data.order_id)
      ) {
        this.checkoutRowErrorDialogVisible = true;
      }

      this.note = "";
      this.getUserCart();
      this.checkoutDialogVisible = true;
    },
    findColorWarehouseInfo(color) {
      return this.$store.state.frontali.colors.find((c) => {
        return c.U_THE_COLORE == color;
      });
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    ...mapState("cart", ["cartRows", "checkoutStatus"]),
    filteredRows() {
      if (this.filter != "") {
        let r = jslinq(this.cartRows);
        return r
          .where((row) => {
            return (
              row.nome_modello.indexOf(this.filter) >= 0 ||
              row.nome_asta.indexOf(this.filter) >= 0
            );
          })
          .toList();
      }

      return this.cartRows;
    },
    warnings() {
      const warnings = {};
      for (let row of this.cartRows) {
        const warehouseInfo = this.findColorWarehouseInfo(row.colore_frontale);
        if (warehouseInfo.GiacIta < 1) {
          warnings[row.colore_frontale] = "Il colore " + row.colore_frontale + " è esaurito, data di arrivo: " + this.formatDate(new Date(warehouseInfo.ShipDateIta));
        }
      }
      return warnings;
    },

    note: {
      get: function () {
        return this.$store.state.note;
      },
      set: function (n) {
        this.$store.state.note = n;
      },
    },
  },
  watch: {
    filteredRows: {
      deep: true,
      handler: function (newVal) {
        if (newVal.length % this.pageSize == 0 && this.page != 1) {
          this.loadPage(this.page - 1);
        } else {
          this.loadPage(this.page);
        }
      },
    },
  },
};
</script>

<style lang="less">
#cart-dialog {
  .note-row {
    margin-top: 15px;
    text-align: left;

    span {
      margin: 8px 0;
      display: block;
    }

    textarea {
      height: 80px;
    }
  }

  .el-dialog__footer {
    .el-row {
      display: flex;
      justify-content: center;
    }
  }
}

#checkout-dialog {
  .el-dialog__body {
    text-align: center;

    span {
      font-weight: bold;
      text-align: center;
      color: @--color-primary;
    }
  }

  .el-dialog__footer {
    .el-row {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>