<template>
  <el-dialog
    id="login-dialog"
    :title="this.isGuest ? 'Inserisci i tuoi dati' : this.user.ragioneSociale"
    :visible="visible"
    width="30%"
    @close="$emit('close')"
  >
    <el-form v-if="isGuest" :rules="rules" :model="loginForm" ref="loginForm">
      <transition name="generic-error">
        <span class="text-danger" v-show="genericLoginError != ''">{{
          genericLoginError
        }}</span>
      </transition>
      <el-row>
        <el-col :span="14" :offset="5">
          <el-form-item label="E-Mail" prop="username">
            <el-input
              placeholder="E-Mail"
              v-model="loginForm.username"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="14" :offset="5">
          <el-form-item label="Password" prop="password">
            <el-input
              placeholder="Password"
              type="password"
              v-model="loginForm.password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button @click="$emit('close')">ANNULLA</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="validate()"> LOGIN </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div v-else class="user-data">
      <ul>
        <li><Email class="list-icon" />{{ user.email }}</li>
        <li><Account class="list-icon" />{{ user.ragioneSociale }}</li>
        <li><Address class="list-icon" />{{ user.address | linearAddress }}</li>
      </ul>
      <div class="data-user-action">
        <el-button type="primary" size="small" @click="logout"
          >LOGOUT</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import Email from "vue-material-design-icons/Email.vue";
import Account from "vue-material-design-icons/Account.vue";
import Address from "vue-material-design-icons/Domain.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Email,
    Account,
    Address,
  },
  filters: {
    linearAddress(a) {
      return `${a.Street}, ${a.ZipCode} - ${a.City}`;
    },
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
          { validator: this.checkEmail, trigger: "blur" },
        ],
        password: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
        ],
      },
      genericLoginError: "",
    };
  },
  methods: {
    ...mapActions("user", ["login", "logout"]),
    checkMail(rule, value, callback) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(value).toLowerCase())) {
        callback();
      } else {
        callback(new Error("Inserire una mail valida"));
      }
    },
    async validate() {
      // this.isLoadingUserData = true;
      let valid = await this.$refs["loginForm"].validate();
      if (valid) {
        // eslint-disable-next-line no-unused-vars
        let r = await this.login({
          username: this.loginForm.username,
          password: this.loginForm.password,
        });
        console.log("r", r);
        console.log(this.userIsGuest);
        // if (this.userIsGuest) {
        if (r.data.error) {
          this.genericLoginError = r.data.msg
            ? r.data.msg
            : "Username o password errati";
        } else {
          this.genericLoginError = "Username o password errati";
        }

        setTimeout(() => {
          this.genericLoginError = "";
        }, 5000);
        // }
      }
      // this.isLoadingUserData = false;
    },
    async userLogin() {
      await this.login(this.loginForm.username, this.loginForm.password);
      // console.log("res", res);
    },
  },
  computed: {
    ...mapGetters("user", ["isGuest"]),
    ...mapState("user", ["user"]),
  },
};
</script>

<style lang="less" >
#login-dialog {
  .el-dialog {
    min-width: 350px;
  }
  .user-data {
    ul {
      list-style-type: none;
      margin: 8px;
      text-align-last: left;
      li {
        margin-top: 8px;
        display: flex;
        align-items: center;
        .list-icon {
          margin-right: 8px;
        }
      }
    }
  }

  .generic-error-enter-active,
  .generic-error-leave-active {
    transition: all 1s;
  }

  .generic-error-enter,
  .generic-error-leave {
    transform: traslateY(20px);
    opacity: 0;
  }
}
</style>