<template>
  <el-dialog
    id="checkout-row-error-dialog"
    :visible="visible"
    title="SI È VERIFICATO UN ERRORE..."
    append-to-body
    width="600px"
    @close="$emit('close')"
  >
    <span
      >SIAMO SPIACENTI, NON È STATO POSSIBILE GENRARE L'ORDINE, <br />
      RIPROVA PIÙ TARDI...</span
    >
    <el-row slot="footer">
      <el-button type="primary" @click="$emit('close')">CHIUDI</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>