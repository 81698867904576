<template>
  <el-row id="action-row" :gutter="5">
    <el-col :span="24">
      <el-button class="confirm-button" @click="$emit('close')" type="info">
        {{ text }}
      </el-button>
    </el-col>
    <!-- <el-col :span="12">
      <el-button class="close-button" type="info" @click="$emit('close')">
        <Close />
      </el-button>
    </el-col> -->
  </el-row>
</template>

<script>
// import Check from "vue-material-design-icons/Check.vue";
// import Close from "vue-material-design-icons/Close.vue";

export default {
  props: {
    text: {
      default: "CONFERMA",
      type: String,
    },
  },
  components: {
    // Check,
    // Close,
  },
};
</script>

<style lang="less">
.confirm-button,
.close-button {
  // altezza bottoni 52px
  width: 100%;
  height: 52px;
}

.confirm-button {
  color: @--color-text-primary !important;
}

.close-button {
  color: @--color-danger !important;
}

#action-row {
  .el-button {
    border-radius: 0;
  }
}
</style>