import Montature from "./Montature.vue";
import Colori from "./Colori.vue";
import Aste from "./Aste.vue";
import Personalizzazioni from "./Personalizzazioni.vue";
import Favorite from "vue-material-design-icons/Heart.vue";
import Edit from "vue-material-design-icons/PencilOutline.vue";
import Check from "vue-material-design-icons/Check.vue";
import Cart from "vue-material-design-icons/Cart.vue";
import Wishlist from "./Wishlist.vue";
import BodyActions from "./BodyActions.vue";
import AddToCartDialog from "./AddToCartDialog.vue";
import ErrorAddToCartDialog from './ErrorAddToCartDialog.vue';

export default {
  Montature,
  Colori,
  Aste,
  Personalizzazioni,
  Favorite,
  Edit,
  Check,
  Cart,
  Wishlist,
  BodyActions,
  AddToCartDialog,
  ErrorAddToCartDialog,
}