import * as THREE from "three";

// var envMapMetal = new THREE.CubeTextureLoader()
//   .setPath("/images/map")
//   .load(["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"]);

// // var metalTextures = new THREE.TextureLoader();

// envMapMetal.format = THREE.RGBFormat;
// envMapMetal.magFilter = THREE.LinearFilter;
// envMapMetal.minFilter = THREE.LinearMipMapLinearFilter;
// envMapMetal.needsUpdate = true;
// envMapMetal.mapping = THREE.CubeReflectionMapping;

const genCubeUrls = (prefix, postfix) => [
	`${prefix}px${postfix}`,
	`${prefix}nx${postfix}`,
	`${prefix}py${postfix}`,
	`${prefix}ny${postfix}`,
	`${prefix}pz${postfix}`,
	`${prefix}nz${postfix}`,
];

const path = process.env.VUE_APP_PUBLIC_PATH;

const urls = genCubeUrls(`${path}images/map/`, ".png");

let loader = new THREE.CubeTextureLoader();
let envMapMetal = loader.load(urls);

envMapMetal.encoding = THREE.sRGBEncoding;

// export const metal_gold_material = new THREE.MeshStandardMaterial({
//   /*color: 0xffef95,
// 	emissive: 0xffef95,*/
//   color: 0x141100,
//   emissive: 0x8e8763,
//   metalness: 1, //0.8
//   roughness: 0.3, //0.7,
//   // envMap: envMapMetal,
//   envMapIntensity: 1.5,
//   name: "metal_material",
//   side: THREE.FrontSide,
//   shadowSide: THREE.FrontSide,
//   needsUpdate: true,
// });
// metalTextures.load('/world/gold_metal.jpg', function(t){
// 	metal_gold_material.map = t;
// 	metal_gold_material.needsUpdate = true;
// });

/*metalTextures.load('/world/preferiti/metal_bump.jpg', function(t){
	metal_gold_material.bumpMap = t;
});*/

// export const metal_gun_material = new THREE.MeshStandardMaterial({
//   color: 0x282828,
//   emissive: 0x302f2f,
//   metalness: 1,
//   roughness: 0.2,
//   envMap: envMapMetal,
//   envMapIntensity: 1.5,
//   name: "metal_material",
//   side: THREE.FrontSide,
//   shadowSide: THREE.FrontSide,
//   needsUpdate: true,
// });

export const metal_gun_material = new THREE.MeshPhysicalMaterial({
	blending: THREE.NormalBlending,
	color: 0xcccccc,
	clearcoat: 0.8,
	clearcoatRoughness: 0.05,
	transparent: false,
	metalness: 0.8,
	roughness: 0.1,
	opacity: 1,
	envMap: envMapMetal,
	envMapIntensity: 2,
});

export const metal_silver = new THREE.MeshPhysicalMaterial({
	blending: THREE.NormalBlending,
	color: 0xcccccc,
	clearcoat: 0.8,
	clearcoatRoughness: 0.05,
	transparent: false,
	metalness: 0.8,
	roughness: 0.1,
	opacity: 1,
	envMap: envMapMetal,
	envMapIntensity: 2,
});

// metalTextures.load('/world/gun_metal.jpg', function(t){
// 	metal_gun_material.map = t;
// 	metal_gun_material.needsUpdate = true;
// });

// export const metal_silver = new THREE.MeshStandardMaterial({
//   color: 0x000000,
//   emissive: 0x727272,
//   emissiveIntensity: 0.7,
//   metalness: 1,
//   roughness: 0.2,
//   transparent: false,
//   side: THREE.FrontSide,
//   shadowSide: THREE.FrontSide,
//   //envMap:      envMapMetal,
//   envMapIntensity: 1.5,
//   needsUpdate: true,
// });
/*metalTextures.load('/textures/metalness_map.jpg', function(m){
	metal_silver.metalnessMap = m;
	metal_silver.needsUpdate = true;
});*/

/*window.onload = function(){
	var gui = new dat.GUI({autoPlace: false});

	//gui.remember(metal_gold_material);

	var cont = document.getElementById('canvas-wrapper');
	cont.appendChild(gui.domElement);


	gui.domElement.style.position = 'absolute';
	gui.domElement.style.top = 0;
	gui.domElement.style.right = 0;


	var conf = {
		color: '#2d2706',
		emissive: '#9e9773',
	};

	gui.addColor(conf, 'color').onChange(function(colore){
		metal_gold_material.color.set(colore);
		//etal_silver.color.set(colore);
	});

	gui.addColor(conf, 'emissive').onChange(function(colore){
		metal_gold_material.emissive.set(colore);
		//metal_silver.emissive.set(colore);
	});*/

/*gui.add(conf, 'emissiveIntensisty', 0, 1, 0.1).onChange(function(colore){
	metal_silver.emissiveIntensity = colore;
});

gui.add(conf, 'metalness', 0, 1, 0.1).onChanage(function(met){
	metal_silver.metalness = met;
});

gui.add(conf, 'roughness', 0, 1, 0.1).onChanage(function(rou){
	metal_silver.roughness = rou;
});

gui.add(conf, 'envInt', 0, 1, 0.1).onChange(function(colore){
	metal_gun_material.envMapIntensity = colore;
});

gui.add(conf, 'roughness', 0, 1, 0.1).onChange(function(colore){
	metal_gun_material.roughness = colore;
});
};*/
