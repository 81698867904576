import axios from "axios";
import _ from "lodash";

const requestBaseUrl = process.env.VUE_APP_REST_URL;

axios.defaults.baseURL = requestBaseUrl;

axios.defaults.headers.common["line-id"] =
  process.env.VUE_APP_CONFIG_IDENTIFIER;
axios.defaults.headers.common["site-id"] =
  process.env.VUE_APP_CONFIG_IDENTIFIER;
if (!_.isEmpty(process.env.VUE_APP_CONFIG_DATABASE)) {
  axios.defaults.headers.common['environment'] = process.env.VUE_APP_CONFIG_DATABASE;
}
export default {
  config: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      "x-requested-with": "XMLHttpRequest",
      Accept: "application/json, text/javascript, */*; q=0.01",
    },
  },
  esclusiva: process.env.VUE_APP_CONFIG_ESCLUSIVA,
  setAuthHeader(identity) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${identity}`;
  },
  /*chiamate per configurazione occhiale*/
  getItems(user) {
    if (!_.isEmpty(user)) {
      return axios.get("/configurator/ajax/get-frontal-models");
    }
    return axios.get("/configurator/ajax/get-frontal-models");
  },
  getTranslations() {
    return axios.post("/configurator/ajax/get-json-translations");
  },
  getAllModelsSizes() {
    return axios.post("/configurator/ajax/get-all-models-sizes");
  },
  getModelSizes(itemCode) {
    return axios.post("/configurator/ajax/get-model-sizes", {
      itemCode,
    });
  },
  getColors() {
    return axios.post("/configurator/ajax/get-colors");
  },
  getColorsCategories() {
    return axios.post("/configurator/ajax/get-colors-categories");
  },
  getTemplesColors() {
    return axios.post("/configurator/ajax/get-temples-colors");
  },
  getTemplesSizes() {
    return axios.post("/configurator/ajax/get-temples-sizes");
  },
  /*chiamate per utente*/
  getUserData() {
    return axios.post("/login/ajax/get-user-data");
  },
  login(username, password) {
    if (this.esclusiva) {
      return axios.post("/login/ajax/login", {
        username,
        password,
        esclusiva: this.esclusiva
      });
    }
    return axios.post("/login/ajax/login", {
      username,
      password,
    });
  },
  logout() {
    return axios.post("/login/ajax/logout");
  },
  /*Funzioni carrello*/
  addToCart(row) {
    return axios.post("/configurator/ajax/add-to-cart", {
      rows: row,
    });
  },
  getUserCart() {
    return axios.post("/configurator/ajax/get-user-cart", {
      // auth_key: auth_key
    });
  },
  deleteCartRow(id) {
    return axios.post("/configurator/ajax/delete-cart-row", {
      // auth_key: auth_key,
      id,
    });
  },
  emptyUserCart() {
    return axios.post("/configurator/ajax/empty-cart", {
      // auth_key: auth_key
    });
  },
  checkout(note) {
    return axios.post("/configurator/ajax/checkout", {
      note,
      // auth_key: auth_key
    });
  },
  /**
   * 
   * @param {Int} row id riga di cui fare il checkout
   * @param {String} note Note da aggiungere all'ordine
   */
  checkoutRow(row, note) {
    return axios.post("/configurator/ajax/checkout-row", {
      note,
      row
    })
  },
  getCountryCode() {
    return axios.get("/configurator/ajax/get-country-code");
  },
  setCountryCode(lang) {
    return axios.get("/configurator/ajax/get-country-code", {
      lang,
    });
  },
  getUserHistory() {
    //auth_key = (auth_key) ? auth_key : null
    return axios.post(`${requestBaseUrl}/configurator/ajax/get-user-history-p`, {
      // params: {
      //   user: user.username,
      //   // auth_key: user.auth_key,
      // },
    });
  },
  getOrdersStatus(ids) {
    return axios.post(`${requestBaseUrl}/configurator/ajax/get-orders-status`, {
      params: {
        ids,
      },
      // paramsSerializer: (params) => {
      //   return qs.stringify(params);
      // },
    });
  },
  getOrderDetails(id) {
    return axios.get(`${requestBaseUrl}/configurator/ajax/get-order-details`, {
      params: {
        orderId: id,
      },
    });
  },
  /*Funzioni per storelocator*/
  getUserCountry() {
    return axios.get("/locator/ajax/get-user-country");
  },
  getCountryStoresActive() {
    return axios.get("/locator/ajax/get-country-stores-active");
  },
  getStores(ccode) {
    return axios.get("/locator/ajax/get-stores", {
      params: {
        ccode,
      },
    });
  },

  /*Funzioni per scrittura e recupero configurazioni da custom da qrcode*/
  setCustomConfigurations(json) {
    return axios.get("/configurator/ajax/set-custom-configuration", {
      params: {
        json,
      },
    });
  },
  getCustomConfigurations(id) {
    return axios.get("/configurator/ajax/get-custom-configuration", {
      params: {
        id,
      },
    });
  },
  getLineKits() {
    return axios.get("/configurator/ajax/get-line-kits");
  },

  sendSendGridMail(data, emailFrom, emailTo, template, sgApiKey) {
    return axios.post("/configurator/ajax/send-send-grid-mail", {
      data,
      emailFrom,
      emailTo,
      template,
      sgApiKey,
    });
  },
};
