<template>
  <div id="colori">
    <ul class="body-title">
      <li class="menu-element active" @click="$emit('close')">
        <span>COLORI</span> <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
    <div class="body-elements">
      <el-row id="filter-row">
        <el-col :span="24">
          <span class="label">FINITURA</span>
          <el-radio-group v-model="finishing">
            <el-radio
              v-for="f in finiture"
              :key="f"
              :label="f | finishingLabel"
              name="finitura"
            ></el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col class="model-filter-column" :span="20">
              <span class="label">CERCA</span>
              <el-input
                v-model="filter"
                size="small"
                clearable
                :placeholder="`Cerca (${colors.length} models)`"
              ></el-input>
            </el-col>
            <el-col class="icon-column" :span="4">
              <Search />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row id="colors-preview-row">
        <el-col
          class="color-preview-column"
          :class="{ active: color.ItemCode == colore.ItemCode }"
          v-for="color in filteredColors"
          :key="color.ItemCode"
          :span="8"
        >
          <div v-if="color.ItemCode == colore.ItemCode" class="active-flag">
            <img src="../../assets/model-active.png" alt="active" />
          </div>
          <div class="color-preview-image" @click="(color.GiacIta < 1 && !color.ShipDateIta) ? null : handleColorClick(color)">
            <div v-if="color.GiacIta < 1 && !color.ShipDateIta" class="color-finished">Colore esaurito</div>
            <img :src="color.U_THE_COLORE | colorImage" alt="" />
          </div>
          <div class="color-preview-name">
            <span>{{ color.U_THE_COLORE }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="body-actions">
      <body-actions @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
import jslinq from "jslinq";
import { mapState, mapActions, mapMutations } from "vuex";
import { Message } from 'element-ui';
import Search from "vue-material-design-icons/Magnify.vue";

import BodyActions from "./BodyActions.vue";
export default {
  components: {
    BodyActions,
    Search,
  },
  data() {
    return {
      finiture: ["S", "M"],
      materiali: [],
      filter: "",
    };
  },
  async mounted() {
    await this.getColors();
  },
  methods: {
    ...mapActions("frontali", ["getColors"]),
    ...mapMutations(["setColore", "setFinishing"]),
    handleColorClick(color) {
      if (parseInt(color.GiacIta) < 1) {
        const c = color.U_THE_COLORE;
        const date = this.formatDate(new Date(color.ShipDateIta));
        Message({
          showClose: true,
          message: "Il colore " + c + " è esaurito, data di arrivo: " + date,
          type: "error",
          duration: 10000,
        });
      }
      this.setColore(color);
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },
  computed: {
    ...mapState(["finitura", "colore"]),
    ...mapState("frontali", ["colors"]),
    filteredColors() {
      let c = jslinq(this.colors);
      if (this.filter != "") {
        return c
          .where((color) => {
            return color.U_THE_COLORE.indexOf(this.filter) >= 0;
          })
          .orderBy((color) => {
            color.Order;
          })
          .toList();
      }
      return c
        .orderBy((color) => {
          return color.Order;
        })
        .toList();
    },
    finishing: {
      get() {
        return this.finitura == "S" ? "Lucido" : "Opaco";
      },
      set(f) {
        let fin = f == "Lucido" ? "S" : "M";
        this.setFinishing(fin);
      },
    },
  },
};
</script>

<style lang="less">

.color-finished {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  cursor: not-allowed;
}

.active-flag {
  z-index: 1;
}

#colors-preview-row {
  height: ~"calc(100% - 30px*3 - 10px*2)"; // 100% - 30 * (numero righe + 30 di padding) + margini * numero righe
}
.color-preview-column {
  position: relative;
  margin-top: auto !important;
  height: auto !important;
  img {
    max-width: 100%;
  }
  .color-preview-image {
    position: relative;
    cursor: pointer;
  }
}
</style>