/* eslint-disable no-unused-vars */
import ajax from "../../plugins/ajax_request";
import _ from "lodash";

const state = () => ({ items: [], itemsSizes: [], colors: [] });

const mutations = {};

const getters = {
  checkErrors(state, getters, rootState) {
    let errors = [];
    if (_.isEmpty(rootState.frontale)) {
      errors.push({ label: "model", message: "Il frontale è obbligatorio" });
    }

    if (rootState.calibro == "" || rootState.naso == "") {
      errors.push({ label: "size", message: "La misura è obbligatoria" });
    }

    if (_.isEmpty(rootState.colore)) {
      errors.push({ label: "color", message: "Il colore è obbligatorio" });
    }

    if (_.isEmpty(rootState.finitura)) {
      errors.push({
        label: "finishing",
        message: "La finitura è obbligatoria",
      });
    }

    return errors;
  },
};

const actions = {
  /**
   * Recupera tutti i modelli disponibili, se la chiamata va a buon fine 
   * imposta un modello casuale
   * @param {*} param0 
   */
  async getItems({ state, commit, mutations }, setRandomModel) {
    let res = await ajax.getItems();
    if (res.status >= 200 && res.status < 300) {
      state.items = res.data;
      if (setRandomModel) {
        let rnd = _.random(state.items.length);
        commit("setFrontal", state.items[rnd], { root: true });
      }
    }
  },
  async getAllModelsSizes({ state }) {
    let res = await ajax.getAllModelsSizes();
    if (res.status >= 200 && res.status < 300) {
      state.itemsSizes = res.data;
    }
  },
  async getColors({ state }) {
    let res = await ajax.getColors();
    if (res.status >= 200 && res.status < 300) {
      state.colors = res.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
