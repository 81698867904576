import * as THREE from "three";
import { SubsurfaceScatteringShader } from "./SubsurfaceScatteringShader.js";
import _ from "lodash";
export default class PlasticMaterial {
  constructor() {
    this.mainMaterial = new THREE.MeshPhysicalMaterial({
      name: "mainMaterial",
      blending: THREE.NormalBlending,
      clearcoat: 1.0,
      clearcoatRoughness: 0.1,
      transparent: true,
      color: 0xffffff,
      metalness: 0,
      roughness: 0.3,
      opacity: 1,
      emissive: 0xffffff,
      emissiveIntensity: -0.04,
      // side: THREE.DoubleSide,
    });

    this.matLens1 = new THREE.MeshPhysicalMaterial({
      name: "matLens",
      blending: THREE.AdditiveBlending,
      color: 0x000000,
      clearcoat: 0.5,
      clearcoatRoughness: 0,
      transparent: true,
      metalness: 1,
      roughness: 0.1,
      opacity: 1,
      refractionRatio: 0.97,
      side: THREE.DoubleSide,
    });

    this.SSS_shader = _.cloneDeep(SubsurfaceScatteringShader);
    this.SSS_uniforms = THREE.UniformsUtils.clone(this.SSS_shader);
    this.SSS_material = new THREE.ShaderMaterial({
      name: "shaderMaterial",
      uniforms: this.SSS_uniforms,
      vertexShader: this.SSS_shader.vertexShader,
      fragmentShader: this.SSS_shader.fragmentShader,
      opacity: 1,
      blending: THREE.AdditiveBlending,
      transparent: true,
      lights: true,
      // depthWrite: false,
      // side: THREE.DoubleSide,
    });

    this.SSS_uniforms.uniforms.diffuse.value = new THREE.Vector3(0.3, 0.3, 0.3);
    this.SSS_uniforms.uniforms.shininess.value = 1;
    this.SSS_uniforms.uniforms.thicknessColor.value = new THREE.Vector3(
      1,
      1,
      1
    );
    this.SSS_uniforms.uniforms.thicknessDistortion.value = 0.8;
    this.SSS_uniforms.uniforms.thicknessAmbient.value = 0.08;
    this.SSS_uniforms.uniforms.thicknessAttenuation.value = 2;
    this.SSS_uniforms.uniforms.thicknessPower.value = 2;
    this.SSS_uniforms.uniforms.thicknessScale.value = 1.5;

    this.SSS_material.uniforms = this.SSS_uniforms.uniforms;
    this.SSS_material.extensions.derivatives = true;
    this.SSS_material.blending = THREE.CustomBlending;
    this.SSS_material.blendSrc = THREE.DstColorFactor;
    this.SSS_material.blendDst = THREE.SrcAlphaFactor;
    this.SSS_material.blendEquation = THREE.AddEquation;
  }

  async getMaterial(envMapIntensity) {
    const genCubeUrls = (prefix, postfix) => [
      `${prefix}px${postfix}`,
      `${prefix}nx${postfix}`,
      `${prefix}py${postfix}`,
      `${prefix}ny${postfix}`,
      `${prefix}pz${postfix}`,
      `${prefix}nz${postfix}`,
    ];

    const path = process.env.VUE_APP_PUBLIC_PATH;

    const urls = genCubeUrls(`${path}images/map/`, ".png");

    let loader = new THREE.CubeTextureLoader();
    let envMap = await loader.load(urls);

    envMap.encoding = THREE.sRGBEncoding;

    this.mainMaterial.reflectivity = 0.9;

    this.mainMaterial.envMap = envMap;

    let clonedTexture = _.cloneDeep(envMap);
    clonedTexture.mapping = THREE.CubeRefractionMapping;
    this.matLens1.envMap = clonedTexture;

    if (envMapIntensity == null) {
      this.mainMaterial.envMapIntensity = 0.8;
      this.matLens1.envMapIntensity = 0.8;
    } else {
      this.mainMaterial.envMapIntensity = envMapIntensity;
      this.matLens1.envMapIntensity = envMapIntensity;
    }

    return [this.mainMaterial, this.SSS_material, this.matLens1];
    // return [this.mainMaterial, this.SSS_material];
  }
}
