import Vue from "vue";
import uaParser from "ua-parser-js"
/**
 * Utilizza un'istanza Vue vuota per emettere eventi,
 * il bus può essere importato in qualsiasi componente
 * nel quale è possibile aggiungere un ascoltatore
 * di eventi sul bus.
 * Il riferimento alla creazione e gestione del bus
 * è preso da qui:
 * 
 * https://www.digitalocean.com/community/tutorials/vuejs-global-event-bus
 * 
 * 
 * import { EventBus } from ""./path/to/EventBus"
 * 
 * EventBus.$emit(event-name)
 * 
 * @params String event-name 
 * @params Function eventHandler
 * EventBus.$on(event-name, eventHandler)
 * 
 * La funzione eventHandler viene eseguita quando sul 
 * bus EventBus viene emesso l'evento event-name.
 * Nel caso sia necessario è possibile rimuovere
 * l'ascoltatore tramite $off
 * 
 * @params String event-name
 * Rimuove l'ascoltatore per l'evento event-name
 * EventBus.$off(event-name)
 * 
 * Rimuove tutti gli ascoltatori registrati
 * EventBus.$off()
 * 
 * Lista eventi gestiti:
 *  - `open-cart`: richiede l'apertura del carrello
 *  - `open-history`: richiede l'apertura dello storico ordini
 *  - `page-resize`: vedi funzione emitPageResize
 *  - `open-menu`: apertura menù laterale
 *  - `close-menu`: chiusura menù laterale
 *  - `open-menu`: apertura menù laterale
 */
export const EventBus = new Vue({
  created() {
    window.addEventListener("resize", this.emitPageResize, false);
    this.emitPageResize();
  },
  data() {
    return {
      deviceWidth: 0,
      deviceHeight: 0,
      deviceIsMobile: false,
      deviceIsTablet: false,
      deviceIsMobileOnly: false,
    }
  },
  methods: {
    /**
     * La funzione emette un evento che notifica un ridimensionamento della pagina
     * associa all'evento un oggetto contenente le principali informazioni riguardo le dimensioni
     * che sono:
     *  - width: larghezza pagina
     *  - height: altezza pagina
     *  - isMobile: identifica se il dispositivo è mobile
     *  - isTablet: identifica se il dispositivo è un tablet
     *  - isMobileOnly: identifica se il dispositivo è solo mobile (smartphone)
     */
    emitPageResize() {
      const device = this.getDeviceData();
      this.deviceWidth = device.width;
      this.deviceHeight = device.height;
      this.deviceIsMobile = device.isMobile
      this.deviceIsTablet = device.isTablet
      this.deviceIsMobileOnly = device.isMobileOnly
      this.$emit('page-resize', {
        width: this.deviceWidth,
        height: this.deviceHeight,
        isMobile: this.deviceIsMobile,
        isTablet: this.deviceIsTablet,
        isMobileOnly: this.deviceIsMobileOnly,
      })
    },
    getDeviceData() {
      const mobileDetect = new uaParser;
      let device = mobileDetect.getDevice();
      return {
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: device.type == 'mobile' || device.type == 'tablet',
        isTablet: device.type == 'tablet',
        isMobileOnly: device.type == 'mobile'
      }
    }
  }
});