import CheckoutDialog from './CheckoutDialog.vue';
import CheckoutErrorDialog from './CheckoutErrorDialog.vue';
import DeleteErrorDialog from './DeleteErrorDialog.vue';
import CheckoutRowErrorDialog from './CheckoutRowErrorDialog.vue'
import ConfirmCheckoutDialog from "./ConfirmCheckoutDialog.vue"
import Cart from "vue-material-design-icons/Cart.vue";
import Search from "vue-material-design-icons/Magnify.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import Check from "vue-material-design-icons/Check.vue";

export default {
  CheckoutDialog,
  CheckoutErrorDialog,
  DeleteErrorDialog,
  CheckoutRowErrorDialog,
  ConfirmCheckoutDialog,
  Cart,
  Search,
  Delete,
  Check
}