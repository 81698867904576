<template>
  <div id="wishlist">
    <ul class="body-title">
      <li class="menu-element active" @click="$emit('close')">
        <Favorite /><span>WISHLIST</span> <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
    <div class="body-elements">
      <el-row id="filter-row">
        <el-button size="small" @click="clearAllConfigurations"
          >SVUOTA</el-button
        >
      </el-row>
      <el-row id="wishlist-preview-row">
        <el-row
          v-for="(config, i) in configurations"
          class="configuration-row"
          :key="i"
          :gutter="8"
        >
          <div v-if="configurationIsActive(config)" class="active-flag">
            <img src="../../assets/model-active.png" alt="active" />
          </div>
          <el-col :span="7">
            <img
              :src="config.frontale.U_THE_MODELLO | modelImage"
              :alt="config.frontale.U_THE_MODELLO"
              @click="restoreConfiguration(i)"
            />
          </el-col>
          <el-col :span="7">
            <img
              :src="config.colore.U_THE_COLORE | colorImage"
              alt=""
              @click="restoreConfiguration(i)"
            />
          </el-col>
          <el-col :span="7">
            <img
              :src="config.coloreAste.U_THE_COLORE | colorImage"
              alt=""
              @click="restoreConfiguration(i)"
            />
          </el-col>
          <el-col :span="3">
            <div class="delete-button" @click="removeConfiguration(i)">
              <Close />
            </div>
          </el-col>
        </el-row>
      </el-row>
    </div>
    <div class="body-actions">
      <body-actions @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import _ from "lodash";

import BodyActions from "./BodyActions.vue";
import Close from "vue-material-design-icons/Close.vue";
import Favorite from "vue-material-design-icons/Heart.vue";

export default {
  components: {
    BodyActions,
    Close,
    Favorite,
  },
  methods: {
    ...mapMutations([
      "restoreConfiguration",
      "removeConfiguration",
      "clearAllConfigurations",
    ]),
    configurationIsActive(c) {
      return _.isEqual(c, this.getActiveConfiguration);
    },
  },
  computed: {
    ...mapState(["configurations"]),
    ...mapGetters(["getActiveConfiguration"]),
  },
  watch: {
    configurations(newVal) {
      if (newVal.length == 0) this.$emit("close");
    },
  },
};
</script>

<style lang="less" >
.configuration-row {
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .active-flag {
    position: absolute;
    top: 0;
  }

  img {
    max-width: 100%;
  }

  .delete-button {
    width: 24px;
    height: 24px;
    border-radius: 15px;
    background-color: @--color-danger;
    color: #fff;
  }
}
</style>