<template lang="html">
  <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   version="1.1"
   id="Livello_1"
   x="0px"
   y="0px"
   viewBox="0 0 841.9 465.3"
   style="enable-background:new 0 0 841.9 465.3;"
   xml:space="preserve"
   sodipodi:docname="personal-name-position.svg"
   inkscape:version="0.92.5 (2060ec1f9f, 2020-04-08)"><metadata
   id="metadata48"><rdf:RDF><cc:Work
       rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
         rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work></rdf:RDF></metadata><defs
   id="defs46" /><sodipodi:namedview
   pagecolor="#ffffff"
   bordercolor="#666666"
   borderopacity="1"
   objecttolerance="10"
   gridtolerance="10"
   guidetolerance="10"
   inkscape:pageopacity="0"
   inkscape:pageshadow="2"
   inkscape:window-width="1848"
   inkscape:window-height="1016"
   id="namedview44"
   showgrid="false"
   inkscape:zoom="1.4345773"
   inkscape:cx="367.3962"
   inkscape:cy="206.36212"
   inkscape:window-x="72"
   inkscape:window-y="27"
   inkscape:window-maximized="1"
   inkscape:current-layer="Livello_1" />
<g
   id="g36"
   transform="matrix(1,0,0,-1,0,511.31397)">
	<path
   class="st0"
   d="M 841.9,46.8 C 823.7,47 805.8,50.4 789.3,57.6 778.6,62.3 767.9,62 757.1,61.5 744.5,61 731.9,59 719.3,58.2 c -14.6,-0.9 -29.3,-1.5 -44,-1.8 -31.5,-0.5 -63,-0.9 -94.5,-0.8 -15.4,0 -30.8,0.9 -46.2,1.7 -13.2,0.7 -26.4,1.7 -39.6,2.6 -7,0.5 -14.1,1 -21.1,1.8 -9.8,1 -19.6,2.4 -29.5,3.5 -6.7,0.7 -13.5,1.1 -20.2,1.8 -5.7,0.7 -11.4,1.8 -17.2,2.6 -5.1,0.7 -10.3,1.2 -15.4,1.8 -8.2,1 -16.4,1.9 -24.6,3.1 -7.9,1.2 -15.8,2.6 -23.7,3.9 -12.9,2.2 -25.8,4.3 -38.7,6.6 -14.2,2.6 -28.5,5.3 -42.6,8.4 -16.6,3.7 -33.2,7.7 -49.7,11.9 -20.6,5.2 -41.1,10.5 -61.5,16.3 -17.3,4.9 -34.3,10.6 -51.5,15.7 -26.6,7.8 -52.3,17.9 -78.3,27.3 -8.5,3.1 -13.1,8.4 -8.4,19.9 5.4,13.2 9.9,26.7 15.1,39.9 1.6,4.1 3.9,8.3 8.7,9.1 2.6,0.4 5.7,-0.3 8.3,-1.2 21,-7.1 41.9,-14.4 62.9,-21.5 11.2,-3.8 22.6,-7.4 33.9,-11 1.2,-0.4 2.6,-0.3 3.9,-0.5"
   id="path4"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 739.9,124.6 c -10.6,-1.6 -21.1,-3.5 -31.7,-4.8 -12.3,-1.5 -24.6,-2.5 -36.9,-3.6 -8,-0.7 -16.1,-1.5 -24.2,-1.7 -20.7,-0.5 -41.3,-1 -62,-0.8 -15.1,0.1 -30.2,0.9 -45.3,1.7 -16.4,0.9 -32.8,2.3 -49.2,3.5 -7.9,0.6 -15.8,1 -23.7,1.8 -9.5,1 -19.1,2.4 -28.6,3.4 -6.1,0.7 -12.3,1.1 -18.5,1.8 -7.1,0.9 -14.1,2 -21.1,3 -9.4,1.3 -18.8,2.6 -28.1,4 -8.2,1.2 -16.4,2.6 -24.6,3.9 -8.5,1.3 -17,2.5 -25.5,4 -9.1,1.6 -18.2,3.4 -27.3,5.3 -10.7,2.2 -21.4,4.8 -32.1,7 -14.7,3 -28.8,7.8 -39.4,18.7 -6.5,6.7 -14,9.5 -22.6,11.8 -17.1,4.5 -34,9.6 -51,14.4 -0.7,0.2 -1.5,0.3 -2.2,0.4"
   id="path6"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 767.1,129 c -5.5,9 -10.6,18.2 -18.4,25.6 -3.6,3.4 -7.4,5.1 -12.4,4.8 -5.7,-0.4 -11.4,-0.7 -17.1,-0.9 -3.6,-0.1 -6.5,-1.8 -8.8,-4.4 -5.2,-5.9 -12,-7.8 -19.4,-7.8 -38.5,-0.4 -77.1,-0.9 -115.6,-0.8 -15.5,0 -31.1,1.6 -46.6,2.5 -6.6,0.4 -13.2,0.4 -19.8,0.9 -10,0.7 -19.9,1.8 -29.9,2.6 -4.5,0.4 -9.1,0.4 -13.6,0.9 -9.8,1.1 -19.6,2.3 -29.5,3.5 -8.2,0.9 -16.4,1.6 -24.6,2.7 -7.1,0.9 -14.1,2.3 -21.1,3.5 -6,0.9 -12,1.8 -18,2.7 -8.1,1.2 -16.1,2.2 -24.2,3.6 -9.7,1.6 -19.4,3.4 -29,5.3 -8.2,1.5 -16.4,3.1 -24.6,4.8 -22,4.8 -44,9.5 -65.9,14.6 -12.1,2.8 -24,6.4 -36.1,9.6 -10.1,2.7 -20.1,6.2 -30.4,7.6 -7.3,1 -15,-0.9 -21.9,-4.1 -2,-0.9 -3.8,-2.3 -5.7,-3.5"
   id="path8"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 841.9,113.6 c -12.3,-0.3 -23.5,4.1 -34.7,8.4 -9.2,3.5 -18.6,5.8 -28.6,5.7 -6.3,-0.1 -12.6,0.4 -18.9,-0.1 -6.2,-0.5 -12.3,-1.9 -18.3,-2.9 -3,2.5 -5.8,5.2 -8.9,7.4 -9.3,6.8 -20,8.1 -31.2,7.5 -10.4,-0.5 -20.8,-1.1 -31.2,-1.3 -20.5,-0.3 -41,-0.6 -61.6,-0.4 -21.1,0.2 -42.2,0.5 -63.3,1.4 -16.1,0.7 -32.2,2.3 -48.4,3.4 -8.5,0.6 -17,1 -25.5,1.8 -10.4,1 -20.8,2.3 -31.2,3.5 -10.4,1.2 -20.8,2.2 -31.2,3.6 -11.2,1.5 -22.3,3.5 -33.4,5.2 -7.6,1.2 -15.3,2.3 -22.9,3.5 -9,1.5 -17.9,3.2 -26.8,4.8 -7.3,1.3 -14.7,2.5 -22,4 -12,2.4 -24.1,4.8 -36,7.5 -15.6,3.5 -31.1,7.2 -46.6,11 -11.5,2.9 -22.9,6.2 -34.3,9.2 -10.4,2.7 -20.6,6.8 -31.6,5.7 -3.1,-0.3 -6.2,-1.4 -9.2,-2.1"
   id="path10"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 37.3,234.5 c -0.9,16.4 -1.8,32.8 -2.6,49.2 -0.4,6.9 -0.6,13.8 -0.9,20.7 -0.3,7.5 -0.6,15 -0.9,22.4 -0.9,19.1 -1.8,38.2 -2.7,57.2 -0.3,7 -0.6,14.1 -0.9,21.1 -0.3,6.9 -0.6,13.8 -0.9,20.7 -0.6,12.9 -1.2,25.8 -1.8,38.7"
   id="path12"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 73.8,223.5 c -0.7,19.9 -1.4,39.9 -2.2,59.8 -0.5,12.9 -1.2,25.8 -1.7,38.7 -0.9,20.2 -1.8,40.5 -2.6,60.7 -0.6,12.9 -1.2,25.8 -1.8,38.7 -0.6,13.8 -1.2,27.6 -1.8,41.3 0,0.5 -0.3,1 -0.4,1.5"
   id="path14"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 128.7,205.1 c 0.5,1.5 1,3 1.2,3.5 -0.8,5.4 -1.5,9.9 -2.1,14.5 -0.9,7.8 -1.5,15.6 -2.7,23.3 -1.5,10 -6.5,18.2 -13.5,25.6 -10.1,10.9 -19.5,22.5 -29.1,33.7"
   id="path16"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 128.7,207.3 c -13.2,4.7 -26.4,9.4 -39.6,14.1"
   id="path18"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 123,247.7 c -4.2,-12 -12.3,-15.5 -22,-13.6 -4.7,0.9 -8,3.6 -10.6,7.5"
   id="path20"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 740.3,125.9 c -1.3,9.8 -6.3,17.9 -12.4,25.5 -2.1,2.6 -4.9,4.7 -7.4,7.1"
   id="path22"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 103.2,264.9 c 9.6,1.1 17.7,-8.3 14.1,-18 -2.2,-5.9 -9.1,-10.9 -18.1,-7"
   id="path24"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 108.1,264 c -3.3,-7.7 -6.5,-15.5 -9.8,-23.2 -10.7,6.6 -6.4,20.2 3.2,23.2"
   id="path26"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 102.8,239.4 c 3.1,7.5 6.2,14.9 9.2,22.4"
   id="path28"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 87.4,218.7 c 1.3,2.5 2.5,5 3.7,7.2 -0.6,5.8 -1.3,11.4 -1.9,17 -0.6,5.2 -2.6,7.1 -7.9,7 -2.6,0 -5.3,-0.3 -7.9,-0.4"
   id="path30"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="M 90.9,295.2 C 84.5,294.6 78,294 71.6,293.4"
   id="path32"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
	<path
   class="st0"
   d="m 71.1,306.2 c 3.5,0.1 7,0.3 10.6,0.4"
   id="path34"
   inkscape:connector-curvature="0"
   style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
</g>
<path
   id="personal-name-path"
   class="st1"
   d="m 37.348234,336.84179 c 0,0 285.999996,110 529.999996,105"
   inkscape:connector-curvature="0"
   style="fill:none;fill-opacity:0.99033813;stroke:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-opacity:0.96135266"
   inkscape:transform-center-x="1.3946088"
   inkscape:transform-center-y="43.540873" />

<text
   font-size="250%"
   id="text41">
	<textPath
   class="personal-name-text"
   xlink:href="#personal-name-path"
   startOffset="5%"
   id="textPath39"
   :style="{ fill: (colore) ? colore.colorCode : '#000000' }">
   {{name}}
	</textPath>
</text>


</svg>
</template>

<script>
export default {
  props:['name', 'colore', 'darkTheme']
}
</script>

<style lang="css" scoped>
  .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .st1{fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .personal-name-text{font-size: 40px}
  .dark{stroke:#ffffff !important;}
</style>
