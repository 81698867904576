<template>
  <div id="canvas-container" style="margin: auto">
    <iframe id="print-frame" frameborder="0"></iframe>

    <!-- <el-dialog
      :visible.sync="isLoading"
      persistent
      :overlay="false"
      max-width="500px"
    >
      <el-card>
        <v-card-text>
          <v-layout wrap justify-center>
            Loading
            <br />
            <v-flex xs12 text-xs-center>
              <b>{{ frontModel }}</b>
            </v-flex>
            {{ loadingGeometry }}%
            <v-progress-linear v-model="loadingGeometry"></v-progress-linear>
          </v-layout>
        </v-card-text>
      </el-card>
    </el-dialog>-->
    <div id="gui"></div>
    <transition name="slide-left-out">
      <div id="popover-multistrato" v-if="dialogMultistrato">
        <el-card>
          <el-row>
            <el-col :span="24">
              <span
                >PER I COLORI MULTISTRATO L'ANTEPRIMA NON E' DISPONIBILE</span
              >
            </el-col>
            <el-col :span="12">
              <div class="colore-esterno">
                <span>COLORE ESTERNO</span>
                <el-image
                  :src="
                    'https://media.thema-optical.com/colori_acetato/frontali/' +
                    this.$store.state.colore.U_THE_COLORE +
                    '.jpg'
                  "
                  fit="scale-down"
                ></el-image>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="colore-interno">
                <span>COLORE INTERNO</span>
                <el-image
                  :src="
                    'https://media.thema-optical.com/colori_acetato/frontali/' +
                    this.$store.state.colore.U_THE_COLORE +
                    '_b.jpg'
                  "
                  fit="scale-down"
                ></el-image>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </transition>
  </div>
</template>

<script>
// import ajax from '../plugins/ajax_request'
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import * as Canvas from "./canvas/index";
import * as THREE from "three";
import "../plugins/OrbitControls";
import modelsHeight from "./canvas/models_height.js";
import * as Metal from "./canvas/metal_materials.js";
import PlasticMaterial from "./canvas/plastic_materials.js";

/**
 * Inizializza il canvas, lancia script di base...
 */

export default {
  props: ["setCanvasPos"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      light: {},
      point_light1: {},
      point_light2: {},
      point_light3_1: {},
      point_light3_2: {},
      point_light3_3: {},
      // id_for_url: "",
      // qrcode: "",
      scene: {},
      plane: {},
      loader: {},
      texture_loader: {},
      render: {},
      camera: {},
      cubeCamera1: {},
      cubeCamera2: {},
      controls: {},
      spot_light_top: {},
      light_intensity: 1,
      front: {}, //modello
      front_material: {},
      temple_dx: {}, //asta_dx
      temple_sx: {}, //asta_sx
      temples: {},
      temples_material: {},
      pin_dx: {}, //asta_sx
      pin_sx: {}, //asta_sx
      pins_material: Metal.metal_silver,
      models_size: modelsHeight,
      width: 0,
      height: 0,
      offsetX: 30,
      rotationX: -1.63,
      offsetY: 1,
      rotationY: 0, //-1.63,
      offsetZ: 90,
      rotationZ: 0, //-0.35,
      componentsCount: 5,
      componentsLoaded: 0,
      loadingGeometry: 0, // % caricamento
      startLoading: false, // definisce quando deve partire il dialog di caricamento geometrie
      updateTemplesGeometry: false, //definisce quando è necessario aggiornare le geometrie delle aste
      plastic_lucid_settings: {
        roughness: 0.3,
        coatRoughness: 0,
        coat: 1,
        envMapIntensity: 0.8,
      },
      plastic_matt_settings: {
        roughness: 0.5,
        coatRoughness: 0.4,
        coat: 0.5,
        envMapIntensity: 0,
      },
      // dialogMultistrato: false,
    };
  },
  async mounted() {
    this.scene = new THREE.Scene();
    let fog = new THREE.Fog(0xf4f4f4, 450, 690);
    this.scene.fog = fog;
    this.scene.position.x = -30;

    this.texture_loader = new THREE.TextureLoader();

    this.render = new THREE.WebGLRenderer({
      antialias: true,
      depthWrite: false,
      preserveDrawingBuffer: true,
      powerPreference: "high-performance",
    });

    var fMaterial = new PlasticMaterial();
    var tMaterial = new PlasticMaterial();
    this.front_material = await fMaterial.getMaterial(
      this.plastic_lucid_settings.envMapIntensity
    );
    this.temples_material = await tMaterial.getMaterial(
      this.plastic_lucid_settings.envMapIntensity
    );

    this.init();
    // this.getQRCode();
    this.controls.update();
    if (this.frontModel) {
      this.loadFront();
      // this.loadTemples();
    }
    this.animate();
    // this.doRender();
  },
  computed: {
    ...mapGetters(["frontModel", "frontColor", "frontSize", "templesColor"]),
    ...mapState(["coloreMetalloAste", "finitura", "finituraAste", "frontali"]),
    ...mapGetters({
      getActiveConfiguration: "getActiveConfiguration",
    }),
    ...Canvas.computed,
  },
  methods: {
    ...mapMutations([
      "stopLoadingGeometry",
      "restoreJsonConfiguration",
      "setFrontal",
    ]),
    ...mapActions(["getCustomConfigurations"]),
    ...Canvas.methods,
  },
  watch: Canvas.watch,
};
</script>

<style  lang="less">
#gui {
  position: absolute;
  top: 200px;
  left: 0px;
  z-index: 10;
}
#canvas-container {
  min-height: 100vh;
  height: 100vh;
  min-width: ~"calc(100vw - 360px)";
  #print-frame {
    position: absolute;
    right: 0;
  }
  canvas {
    position: absolute;
    transition-duration: 0.3s;
    right: 0;
  }
}

.slide-left-out-enter-active {
  transition: all 0.3s ease;
}
.slide-left-out-leave-active {
  transition: all 0.3s;
}
.slide-left-out-enter,
.slide-left-out-leave-to {
  transform: translateX(-100vh);
  opacity: 0;
}
</style>
