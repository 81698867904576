<template>
  <div id="left-menu">
    <div id="left-menu-body">
      <transition name="slide-left-out" mode="out-in">
        <ul v-if="activeMenu == null">
          <li
            v-for="item in menuNames"
            :key="item.id"
            class="menu-element"
            :class="{
              completed: isCompleted(item.name),
              hidden: item.name == 'WISHLIST' && !wishlistVisible,
            }"
            @click="activeMenu = item.name"
          >
            <span class="item-name"> {{ item.name }}</span>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
        <div v-else id="left-menu-active-body">
          <component
            v-if="activeComponent != null"
            :is="activeComponent"
            @close="activeMenu = null"
          ></component>
        </div>
      </transition>
    </div>

    <div id="left-menu-footer" :class="{ active: expandRecap }">
      <el-row class="recap-title-wrapper">
        <span class="recap-title">RIEPILOGO</span>
      </el-row>
      <el-row class="recap-data" :gutter="15" v-if="!expandRecap">
        <el-col :span="12" class="montatura">
          <span class="recap-data-title">MONTATURA</span>
          <ul>
            <li>Modello: {{ frontale.U_THE_MODELLO }}</li>
            <li>Misura: {{ frontSize }}</li>
            <li>Colore: {{ colore.U_THE_COLORE }}</li>
          </ul>
        </el-col>
        <el-col :span="12" class="aste">
          <span class="recap-data-title">ASTE</span>
          <ul>
            <li>Materiale: {{ templesMaterial | materialLabel }}</li>
            <li>Misura: {{ misuraAste }}</li>
            <li>Colore: {{ coloreAste.U_THE_COLORE }}</li>
          </ul>
        </el-col>
      </el-row>
      <el-row class="recap-data" :gutter="15" v-else>
        <el-col :span="12" class="full-recap">
          <ul>
            <li>
              Modello: <br />
              {{ frontale.U_THE_MODELLO }}
            </li>
            <li>
              Misura: <br />
              {{ frontSize }}
            </li>
            <li>
              Colore: <br />
              {{ colore.U_THE_COLORE }}
            </li>
            <li>
              Materiale: <br />
              {{ templesMaterial | materialLabel }}
            </li>
            <li>
              Misura: <br />
              {{ misuraAste }}
            </li>
            <li>
              Colore: <br />
              {{ coloreAste.U_THE_COLORE }}
            </li>
            <li>
              Personalizzazione: <br />
              {{ nome }}
            </li>
            <li>
              Colore: <br />
              {{ coloreNome }}
            </li>
            <li>
              Note:
              <Edit
                class="edit-note"
                v-if="!editNote"
                @click="editNote = !editNote"
              />
              <br />
              <span v-if="!editNote">{{ note }}</span>
              <div v-else class="edit-note-action-wrapper">
                <el-input
                  type="textarea"
                  v-model="rowNote"
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="editNote = false"
                ></el-input>
                <el-button
                  size="mini"
                  class="pull-right"
                  @click="editNote = !editNote"
                >
                  <Check class="edit-note" />
                </el-button>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row :gutter="15" class="recap-data-actions">
        <el-col v-if="expandRecap" :span="18" :offset="3" class="text-center">
          CLICCA AGGIUNGI PER AGGIUNGERE AL CARRELLO <br />
          <small class="text-extra-small"
            >Il reso dei prodotti personalizzati è possibile solo per difetti di
            fabbricazione</small
          >
        </el-col>
        <el-col :span="12">
          <el-button
            size="mini"
            class="action-button"
            :disabled="!configurationComplete"
            @click="saveConfiguration"
          >
            <Favorite :size="18" class="button-icon" /> <span>SALVA</span>
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button
            size="mini"
            :disabled="!canAddToCart"
            class="action-button"
            @click="
              expandRecap == false ? (expandRecap = true) : addToUserCart()
            "
          >
            <Cart :size="18" class="button-icon" />
            <span>AGGIUNGI</span>
          </el-button>
        </el-col>
        <el-col class="recap-body-action" :span="24">
          <BodyActions
            v-if="expandRecap"
            @close="expandRecap = false"
            text="INDIETRO"
          />
        </el-col>
      </el-row>
    </div>
    <AddToCartDialog
      :isVisible="addToCartDialogVisible"
      @close="addToCartDialogVisible = false"
    />
    <ErrorAddToCartDialog
      :isVisible="errorAddToCartDialogVisible"
      @close="errorAddToCartDialogVisible = false"
    />
  </div>
</template>

<script>
import jslinq from "jslinq";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { EventBus } from "../plugins/EventBus";

import Components from "./left-menu";

export default {
  components: Components,
  data() {
    return {
      activeMenu: null,
      expandRecap: false,
      editNote: false,
      addToCartDialogVisible: false,
      errorAddToCartDialogVisible: false,
      visible: true,
      menuNames: [
        {
          id: 1,
          name: "MONTATURA",
          component: Components.Montature,
          visible: true,
        },
        {
          id: 2,
          name: "COLORE",
          component: Components.Colori,
          visible: true,
        },
        {
          id: 3,
          name: "ASTE",
          component: Components.Aste,
          visible: true,
        },
        {
          id: 4,
          name: "PERSONALIZZAZIONE",
          component: Components.Personalizzazioni,
          visible: true,
        },
        {
          id: 5,
          name: "WISHLIST",
          component: Components.Wishlist,
          visible: this.wishlistVisible,
        },
      ],
    };
  },
  async mounted() {
    await this.getItems(true);
    await this.getColors();
    await this.getTemplesColors();

    // const c = jslinq(this.colors),
    //   black = c.firstOrDefault((col) => {
    //     return col.U_THE_COLORE == "PL0001";
    //   }),
    //   ca = jslinq(this.coloriAste),
    //   blackAste = ca.firstOrDefault((col) => {
    //     return col.U_THE_COLORE == "PL0001" && col.U_THE_LUNGASTA == 145;
    //   });
    // // self = this,
    // this.setColore(black);
    // this.setTemplesColor(blackAste);
  },
  methods: {
    ...mapActions("frontali", ["getItems", "getColors"]),
    ...mapActions("aste", { getTemplesColors: "getAllColors" }),
    ...mapActions("cart", ["addToCart"]),
    ...mapMutations([
      "saveConfiguration",
      "setRowNotes",
      "setColore",
      "setTemplesColor",
      "resetConfiguration",
    ]),
    isCompleted(menuName) {
      switch (menuName) {
        case "MONTATURA":
          return this.modelErrors && this.modelErrors.length == 0;

        case "COLORE":
          return this.colorErrors && this.colorErrors.length == 0;

        case "ASTE":
          return this.templesErrors && this.templesErrors.length == 0;

        case "PERSONALIZZAZIONE":
          return this.nome != "";
      }
    },
    async addToUserCart() {
      let response = await this.addToCart();
      console.log(response);
      if (
        response.status >= 200 &&
        response.status < 300 &&
        response.data.status.success
      ) {
        this.addToCartDialogVisible = true;
        this.expandRecap = false;
        EventBus.$emit("reset-configuration");
        this.resetConfiguration({
          forma: null,
          colore: null,
          coloreAste: null,
        });
      } else {
        this.errorAddToCartDialogVisible = true;
      }
    },
  },
  computed: {
    ...mapState([
      "frontale",
      "colore",
      "misuraAste",
      "coloreAste",
      "configurations",
      "nome",
      "coloreNome",
      "note",
    ]),
    ...mapState("frontali", ["colors"]),
    ...mapState("aste", { coloriAste: "allColors" }),
    ...mapGetters("frontali", ["checkErrors"]),
    ...mapGetters(["frontSize", "templesMaterial"]),
    ...mapGetters("aste", { templesErrors: "checkErrors" }),
    ...mapGetters("cart", ["canAddToCart"]),
    ...mapGetters(["configurationComplete"]),
    activeComponent() {
      if (this.activeMenu != null) {
        let mn = jslinq(this.menuNames),
          am = this.activeMenu;
        let res = mn.firstOrDefault((e) => {
          return e.name == am;
        });

        return res.component;
      }

      return null;
    },
    rowNote: {
      get() {
        return this.note;
      },
      set(n) {
        this.setRowNotes(n);
      },
    },
    modelErrors() {
      let e = jslinq(this.checkErrors);
      return e
        .where((error) => {
          return error.label == "model" || error.label == "size";
        })
        .toList();
    },
    colorErrors() {
      let e = jslinq(this.checkErrors);
      return e
        .where((error) => {
          return error.label == "color";
        })
        .toList();
    },
    wishlistVisible() {
      return this.configurations && this.configurations.length > 0;
    },
  },
};
</script>

<style lang="less" >
#left-menu {
  position: relative;
  #left-menu-footer,
  #left-menu-body {
    min-width: 100%;
  }

  #left-menu-body {
    height: 75vh;
    position: absolute;
    background-color: @--border-color-base;
    max-width: @--menu-width;
    min-width: @--menu-width;
    #left-menu-active-body {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li.menu-element {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
        border-top: solid @--border-color-base 1px;
        border-bottom: solid @--border-color-base 1px;
        background-color: @--border-color-light;
        cursor: pointer;

        &.active,
        &.completed {
          // classe usata nei componenti di left-menu
          background-color: @--color-black;
          color: @--color-white;
        }
      }
    }

    .body-elements {
      min-height: ~"calc(100% - 104px)"; // altezza totale - body-titel - body-actions
      max-height: ~"calc(100% - 104px)"; // altezza totale - body-titel - body-actions
      height: ~"calc(100% - 104px)"; // altezza totale - body-titel - body-actions
    }
    .body-actions {
      position: absolute;
      min-width: 100%;
    }
  }

  .slide-left-out-enter-active {
    transition: all 0.3s ease;
  }
  .slide-left-out-leave-active {
    transition: all 0.3s;
  }
  .slide-left-out-enter,
  .slide-left-out-leave-to {
    transform: translateX(-360px);
    opacity: 0;
  }
  #left-menu-footer {
    display: flex;
    flex-direction: column;
    align-content: center;
    min-width: 100%;
    max-width: 360px;
    height: 25vh;
    background-color: @--color-primary;
    color: @--color-white;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
    position: relative;
    top: 75vh;
    justify-content: center;
    transition-duration: 0.5s;

    &.active {
      position: absolute;
      height: 100vh;
      top: 0;
      justify-content: space-between;

      .full-recap {
        li {
          margin-top: 10px;

          .edit-note {
            margin-left: 10px;
            cursor: pointer;
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }

        .edit-note-action-wrapper {
          .el-button--mini {
            float: right;
            margin-top: 3px;
            padding: 3px 15px !important;
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }

      .recap-body-action {
        height: 52px;
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
      .recap-data-actions > .el-col {
        margin-bottom: 15px;
      }
    }

    .recap-title-wrapper {
      font-weight: bold;
      text-align: center;
      left: -8px;
    }
    .recap-data {
      margin-top: 8px;

      .recap-data-title {
        padding: 8px;
        font-weight: 100;
      }

      ul {
        list-style-type: none;
        text-align: left;
        padding-left: 8px;
        font-weight: 100;
      }
    }
    .recap-data-actions {
      .action-button {
        width: 100%;
        & > span {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .button-icon {
          padding: 0 5px;
        }
      }
    }
  }
}
</style>