export default [
  {
    model: 'DC-001',
    height: 51.20,
    angle: 10,
  },
  {
    model: 'DC-002',
    height: 54.60,
    angle: 10,
  },
  {
    model: 'DC-003',
    height: 54.80,
    angle: 8,
  },
  {
    model: 'DC-004',
    height: 47.00,
    angle: 10,
  },
  {
    model: 'DC-005',
    height: 45.30,
    angle: 10,
  },
  {
    model: 'DC-006',
    height: 42.40,
    angle: 10,
  },
  {
    model: 'DC-007',
    height: 47.47,
    angle: 10,
  },
  {
    model: 'DC-008',
    height: 46.70,
    angle: 10,
  },
  {
    model: 'DC-009',
    height: 47.30,
    angle: 10,
  },
  {
    model: 'DC-010',
    height: 45.50,
    angle: 10,
  },
  {
    model: 'DC-011',
    height: 51.40,
    angle: 10,
  },
  {
    model: 'DC-012',
    height: 48.90,
    angle: 10,
  },
  {
    model: 'DC-013',
    height: 46.40,
    angle: 10,
  },
  {
    model: 'DC-014',
    height: 50.10,
    angle: 10,
  },
  {
    model: 'DC-015',
    height: 42.74,
    angle: 10,
  },
  {
    model: 'DC-016',
    height: 45.62,
    angle: 10,
  },
  {
    model: 'DC-017',
    height: 45.40,
    angle: 10,
  },
  {
    model: 'DC-018',
    height: 42.60,
    angle: 10,
  },
  {
    model: 'DC-019',
    height: 40.63,
    angle: 10,
  },
  {
    model: 'DC-020',
    height: 41.41,
    angle: 10,
  },
  {
    model: 'DC-021',
    height: 50.30,
    angle: 10,
  },
  {
    model: 'DC-022',
    height: 46.80,
    angle: 10,
  },
  {
    model: 'DC-023',
    height: 52.10,
    angle: 10,
  },
  {
    model: 'DC-024',
    height: 47.40,
    angle: 10,
  },
  {
    model: 'DC-026',
    height: 48.51,
    angle: 10,
  },
  {
    model: 'DC-027',
    height: 44.70,
    angle: 10,
  },
  {
    model: 'DC-028',
    height: 57.56,
    angle: 10,
  },
  {
    model: 'DC-029',
    height: 55.84,
    angle: 10,
  },
  {
    model: 'DC-030',
    height: 49.93,
    angle: 10,
  },
  {
    model: 'DC-031',
    height: 48.04,
    angle: 10,
  },
  {
    model: 'DC-032',
    height: 52.39,
    angle: 10,
  },
  {
    model: 'DC-033',
    height: 53.25,
    angle: 10,
  },
  {
    model: 'DC-035',
    height: 50.27,
    angle: 10,
  },
  {
    model: 'DC-036',
    height: 46.78,
    angle: 10,
  },
  {
    model: 'DC-037',
    height: 52.08,
    angle: 10,
  },
]
