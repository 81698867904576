import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import Element from "element-ui";
import "./theme/index.css";
import "./theme/de-menego.less";
import locale from "element-ui/lib/locale/lang/it";
import VueCookie from "vue-cookies";

Vue.config.productionTip = false
Vue.use(Element, { locale })
Vue.use(VueCookie)

Vue.filter('colorImage', c => {
  return `${process.env.VUE_APP_COLORS_URL}frontali/${c}.jpg`;
})
Vue.filter('finishingLabel', f => {
  return f == "S" ? "Lucido" : "Opaco";
})
Vue.filter('materialLabel', m => {
  return m == "PL" ? "Plastica" : "Metallo";
})

Vue.filter('modelImage', m => {
  return `${process.env.VUE_APP_MODEL_URL + m}.png`;
})

//  TODO: eliminare variabili relative a sendgrid nei file ambiente
new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
